import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function LoginFormHorizontal({ handleLogin }) {
  const { t } = useTranslation();
  return (
    <Container className="pt-3">
      <Form onSubmit={handleLogin}>
        <Form.Group as={Row}>
          <Col sm="5">
            <Form.Control
              //type="email"
              placeholder={t("login.email")}
            />
          </Col>
          <Col sm="5">
            <Form.Control type="password" placeholder={t("login.password")} />
          </Col>
          <Col sm="2">
            <Button type="submit" className="custom-btn">
              ▶
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
}
