//Page d'accueil

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function Landing() {
  const { t } = useTranslation();

  return (
    <Container className="landing text-center">
      <Row className="landing-content-row text-left">
        <Col lg="6">
          <h1 className="mt-3 display-3mainTitle">{t("common.title")}</h1>
        </Col>
        <Col lg="6">
          <p className="paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis
            donec et odio pellentesque diam. Tortor at risus viverra adipiscing
            at in. Viverra tellus in hac habitasse. Id donec ultrices tincidunt
            arcu non sodales neque sodales. Et ligula ullamcorper malesuada
            proin libero nunc consequat.
          </p>
        </Col>
      </Row>

      <Button
        size="lg"
        className="custom-btn mb-5"
        onClick={() =>
          window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
          })
        }
      >
        {t("common.learnMore")}
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            fill="none"
            stroke="#231f20"
            strokeWidth="1"
            d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
          />
        </svg>
      </Button>
    </Container>
  );
}
