import React, { useMemo, useCallback, useState, useEffect } from "react";
import abcjs from "abcjs";
const instrumentPicker = (instrument) => {
  switch (instrument) {
    case "piano":
      return 1;
    case "guitar":
      return 25;
    case "violin":
      return 40;
    default:
      return 1;
  }
};

//TODO: décompte?
const usePortee = (
  cardType,
  porteeId,
  abcInfo,
  options,
  previewMode = false
) => {
  const { bpm, instrument } = options;
  const { timeSig, musicKey, clef, abcString } = abcInfo;

  const playId = porteeId + "-play";
  const synthControl = useMemo(() => new abcjs.synth.SynthController(), []);

  const [loadedSynth, setLoadedSynth] = useState(false);

  const audioParams = useMemo(() => {
    setLoadedSynth(false);
    return {
      soundFontUrl: process.env.PUBLIC_URL + "/assets/sounds/",
      program: instrumentPicker(instrument),
      midiTranspose: cardType === "rythm" ? -33 : 0,
    };
  }, [cardType, instrument]);

  const [looping, setLooping] = useState(false);

  const renderAbc = useCallback(() => {
    //console.log("RENDER ABC", porteeId);
    let fullABCstring = `Q: 1/4=${bpm}\nL: ${timeSig} \nK: ${musicKey} clef=${clef}\n${abcString}`;
    if (looping) {
      //Peut-être un jour
      //fullABCstring = fullABCstring.slice(0, -1) + ":|";
    }
    return abcjs.renderAbc(porteeId, fullABCstring, {
      add_classes: true,
      responsive: "resize",
      staffwidth: cardType === "chords" || cardType === "intervals" ? 90 : 158,
      paddingbottom: cardType === "chords" || cardType === "intervals" ? 0 : 0,
      paddingright: 15,
      paddingleft: 15,
      paddingtop:
        previewMode === true
          ? 0
          : cardType === "chords" || cardType === "intervals"
          ? 30
          : 0,
      clickListener: (abcElem) => {
        //console.log(abcElem);
        // Lorsqu'on clique sur les éléments de la portée.
        if (abcElem.midiPitches) {
          abcjs.synth
            .playEvent(abcElem.midiPitches, [], (60000 / bpm) * 4)
            .then(function (response) {
              //console.log("note played");
            })
            .catch(function (error) {
              console.warn("error playing note", error);
            });
        }
      },
    });
  }, [
    timeSig,
    musicKey,
    clef,
    abcString,
    cardType,
    bpm,
    porteeId,
    looping,
    previewMode,
  ]);

  const [visualObj, setVisualObj] = useState(renderAbc());
  useEffect(() => {
    setVisualObj(renderAbc());
  }, [renderAbc]);

  const cursorControl = useMemo(() => {
    class CursorControl {
      // L'objet qui sera créé permet d'afficher la note en cours lorsqu'on fait jouer.

      constructor(rootSelector) {
        this.beatSubdivisions = 1;
        this.onStartCallback = () => {};
        this.onFinishedCallback = () => {};

        this.onStart = function () {
          // Désactive des éléments de l'interface qui peuvent faire bugger la carte, comme changer d'instruments ou de tempo.
          //console.log("STARTED PLAYBACK");
          this.onStartCallback();
        };
        this.onFinished = function () {
          //console.log("The tune has stopped playing.");
          // Unselect any previously selected notes.
          //console.log("FINISHED PLAYBACK");
          this.onFinishedCallback();
          var lastSelection = document.querySelectorAll(
            rootSelector + " .abcjs-highlight"
          );
          for (var k = 0; k < lastSelection.length; k++)
            lastSelection[k].classList.remove("abcjs-highlight");
        };

        this.onEvent = function (ev) {
          this.removeSelection = function () {
            // Unselect any previously selected notes.
            var lastSelection = document.querySelectorAll(
              rootSelector + " .abcjs-highlight"
            );
            for (var k = 0; k < lastSelection.length; k++)
              lastSelection[k].classList.remove("abcjs-highlight");
          };

          // This is called every time a note or a rest is reached and contains the coordinates of it.
          if (ev.measureStart && ev.left === null) return; // this was the second part of a tie across a measure line. Just ignore it.
          this.removeSelection();

          // Select the currently selected notes.
          for (var i = 0; i < ev.elements.length; i++) {
            var note = ev.elements[i];
            for (var j = 0; j < note.length; j++) {
              let currentClass = note[j].className.baseVal;
              let currentCard = document.getElementById(porteeId);
              let elemSVG = currentCard.getElementsByClassName(currentClass);

              elemSVG[0].classList.add("abcjs-highlight");

              //note[j].classList.add("abcjs-highlight");
            }
          }

          /* console.log("An event is happening", ev); */
        };
      }
    }
    return new CursorControl(`#${porteeId}`, `#${playId}`);
  }, [porteeId, playId]);

  const loadSynth = useCallback(() => {
    if (previewMode !== true) {
      //console.log("RELOADED SYNTH");
      synthControl.load(`#${playId}`, cursorControl);
      if (abcjs.synth.supportsAudio()) {
        synthControl
          .setTune(visualObj[0], true, audioParams)
          .then(function () {
            //console.log("Audio successfully loaded.");
          })
          .catch(function (error) {
            console.warn("Audio problem:", error);
          });
      }
    }
  }, [
    audioParams,
    cursorControl,
    playId,
    synthControl,
    visualObj,
    previewMode,
  ]);

  useEffect(() => {
    if (!loadedSynth && visualObj.length > 0) {
      // console.log("visualObj", visualObj);
      setLoadedSynth(true);

      loadSynth();
    }
  }, [synthControl, loadedSynth, visualObj, cursorControl, playId, loadSynth]);

  const toggleLoop = () => {
    setVisualObj(renderAbc());
    loadSynth();
    setLooping(!looping);
  };

  const play = useCallback(
    (e, onStartCallback = () => {}, onFinishedCallback = () => {}) => {
      e.stopPropagation();
      // console.log("PLAY CALLS LOAD SYNTH");
      cursorControl.onStartCallback = onStartCallback;
      cursorControl.onFinishedCallback = onFinishedCallback;
      loadSynth();
      if (looping) {
        synthControl.toggleLoop();
      }
      try {
        synthControl.play();
      } catch {
        console.log("non");
      }
    },
    [synthControl, loadSynth, cursorControl, looping]
  );

  const stop = useCallback(
    (e) => {
      e.stopPropagation();
      cursorControl.onFinished();
      //console.log("STOP CALLS LOAD SYNTH");
      setVisualObj(renderAbc());
      loadSynth();
    },
    [loadSynth, renderAbc, cursorControl]
  );

  const Portee = useCallback(() => {
    //console.log("RENDER PORTEE", porteeId);
    return (
      <>
        <div id={`${porteeId}`} />
        <div id={`${playId}`} style={{ display: "none" }} />
      </>
    );
  }, [porteeId, playId]);

  return {
    play,
    stop,
    toggleLoop,
    Portee,
  };
};

export default usePortee;
