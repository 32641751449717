import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function LoginFormVertical({ handleLogin }) {
  const { t } = useTranslation();
  return (
    <Container className="login-form text-left pt-4 pb-1 px-2">
      <Form onSubmit={handleLogin}>
        <Form.Group as={Row}>
          <Form.Label column sm={4}>
            {t("login.email")}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              //type="email"
              placeholder={t("login.emailPlaceholder")}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4}>
            {t("login.password")}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              type="password"
              placeholder={t("login.passwordPlaceholder")}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={{ span: 8, offset: 4 }}>
            <Button type="submit" className="custom-btn">
              {t("login.connect")}
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
}
