//Page plus ou moins accessible, mais qui sert à déposer les index pour les cartes physiques.

import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import "./IndexCards.scss";

export default function IndexCards() {
  const index1 = process.env.PUBLIC_URL + "/assets/index/Index-01.png";
  const index2 = process.env.PUBLIC_URL + "/assets/index/Index-02.png";
  const index3 = process.env.PUBLIC_URL + "/assets/index/Index-03.png";
  const index4 = process.env.PUBLIC_URL + "/assets/index/Index-04.png";
  const index5 = process.env.PUBLIC_URL + "/assets/index/Index-05.png";
  const index6 = process.env.PUBLIC_URL + "/assets/index/Index-06.png";
  const index7 = process.env.PUBLIC_URL + "/assets/index/Index-07.png";
  const index8 = process.env.PUBLIC_URL + "/assets/index/Index-08.png";
  const index9 = process.env.PUBLIC_URL + "/assets/index/Index-09.png";
  const index10 = process.env.PUBLIC_URL + "/assets/index/Index-10.png";
  const index11 = process.env.PUBLIC_URL + "/assets/index/Index-11.png";
  const index12 = process.env.PUBLIC_URL + "/assets/index/Index-12.png";
  const index13 = process.env.PUBLIC_URL + "/assets/index/Index-13.png";
  const index14 = process.env.PUBLIC_URL + "/assets/index/Index-14.png";
  const index15 = process.env.PUBLIC_URL + "/assets/index/Index-15.png";
  const index16 = process.env.PUBLIC_URL + "/assets/index/Index-16.png";
  const index17 = process.env.PUBLIC_URL + "/assets/index/Index-17.png";
  const index18 = process.env.PUBLIC_URL + "/assets/index/Index-18.png";
  const index19 = process.env.PUBLIC_URL + "/assets/index/Index-19.png";
  const index20 = process.env.PUBLIC_URL + "/assets/index/Index-20.png";
  const { t, i18n } = useTranslation();
  const imageSelect = {
    fr: [index1, index5, index9, index13, index14],
    en: [index2, index6, index10, index15, index16],
    spa: [index3, index7, index11, index17, index18],
    por: [index4, index8, index12, index19, index20],
  };
  return (
    <>
      <Helmet>
        <title>
          {t("indexcards.title")} | {t("common.title")}
        </title>
      </Helmet>
      <h1 className="section-title my-5">{t("indexcards.title")}</h1>
      {imageSelect[i18n.language].map((element, index) => (
        <a href={element}>
          <img src={element} alt={`index ${index}`} className="index-preview" />
        </a>
      ))}
    </>
  );
}
