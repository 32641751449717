// Le dos de la carte

import React from "react";

import rythmique_verso from "../../resources/media/rythm_fr.png";
import accords_verso from "../../resources/media/chords_fr.png";
import creativite_verso from "../../resources/media/creativity_fr.png";
import intervalles_verso from "../../resources/media/intervals_fr.png";
import melodique_verso from "../../resources/media/melody_fr.png";
import symboles_verso from "../../resources/media/symbols_fr.png";
import notes_alterations_verso from "../../resources/media/notesAlterations_fr.png";

//TODO: Langues multiples

export default function CarteVerso({ cardType, handleFlip, cardDimensions }) {
  const imageTable = {
    rythm: rythmique_verso,
    chords: accords_verso,
    creativity: creativite_verso,
    intervals: intervalles_verso,
    melody: melodique_verso,
    symbols: symboles_verso,
    notesAlterations: notes_alterations_verso,
  };

  return (
    <img
      style={{ height: cardDimensions.height, width: cardDimensions.width }}
      src={imageTable[cardType]}
      alt=""
      className="verso-image"
      draggable={false}
      onClick={handleFlip}
    />
  );
}
