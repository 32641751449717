// Ce fichier contient la grille elle-même et les éléments de la grille de cartes.

import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { CardContext } from "../../context/CardContext";
import Carte from "../cartes/Carte";
import OverlayConditionnalButton from "../../components/utils/OverlayConditionnalButton";
import { flushSync } from "react-dom";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

export default function CardGrid({ sideBar }) {
  const { cardOptions, cardsInfo } = useContext(CardContext);
  let colWidth = Math.floor(12 / cardOptions.width);

  return (
    <Row className="m-0 card-field">
      {cardsInfo.cardsInGrid.map((cardID, index) => (
        <GridElement
          key={index}
          cardID={cardID}
          width={colWidth}
          index={index}
          sideBar={sideBar}
        />
      ))}
    </Row>
  );
}

function GridElement({ width, cardID, index, sideBar }) {
  // Charge l'état global des cartes.
  const { cardsInfo, setCardsInfo, cardOptions } = useContext(CardContext);

  const { t } = useTranslation();
  const targetRef = useRef();
  const [gridElementDimensions, setGridElementDimensions] = useState({});
  let movement_timer = useRef(null);
  const RESET_TIMEOUT = 100;

  const test_gridElementDimensions = () => {
    if (targetRef.current) {
      const newGridElementDimensions = {
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      };
      setGridElementDimensions(newGridElementDimensions);
    }
  };

  const onResize = useCallback(() => {
    clearInterval(movement_timer.current);
    movement_timer.current = setTimeout(
      test_gridElementDimensions,
      RESET_TIMEOUT
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  useLayoutEffect(() => {
    test_gridElementDimensions();
  }, [cardOptions.width, cardOptions.height, sideBar]);

  // Afficher une carte dès le premier render s'il y a déjà une carte dans la liste de cartes à afficher
  const [cardToDisplay, setCardToDisplay] = useState(
    cardID ? cardsInfo.allCards.find((el) => el.id === cardID) : null
  );

  // Permet de re-renderer le component au besoin.
  const [boolComponentChange, setBoolComponentChange] = useState(false);

  const _addCard = (drawFrom, drawThis) => {
    removeCard();
    const tempCard = drawFrom[drawThis];

    const tempCardArray = cardsInfo.cardsInGrid;
    tempCardArray[index] = tempCard;
    setCardToDisplay(tempCard);
    setCardsInfo((prev) => ({
      ...prev,
      cardsInGrid: tempCardArray,
      availableCards: cardsInfo.allCards
        .filter((card) => card.selected)
        .filter((card) => !isInGrid(card)),
    }));
  };

  const dropped = (e) => {
    // Re-render avec la carte
    setBoolComponentChange(!boolComponentChange);
    _addCard(
      cardsInfo.allCards,
      cardsInfo.allCards.findIndex(
        (el) => el.id.toString() === e.dataTransfer.getData("id")
      )
    );

    // Enlève le cadrage de drag and drop
    e.target.className = e.target.className.replace(" draggedInto", "");
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    // Affiche un cadrage lorsqu'on drag dedans.
    try {
      e.target.className = e.target.className + " draggedInto";
    } catch (e) {}
  };
  const dragLeave = (e) => {
    // Enlève le cadrage
    try {
      e.target.className = e.target.className.replace(" draggedInto", "");
    } catch (e) {}
  };

  const isInGrid = (cardToValidate) => {
    let isInGrid = false;
    const idToValidate = cardToValidate.id;
    cardsInfo.cardsInGrid
      .filter((o) => o)
      .forEach((card) => {
        if (card.id === idToValidate) {
          isInGrid = true;
        }
      });

    return isInGrid;
  };

  const drawCard = () => {
    // Permet de piger une carte qui n'est pas présentement affichée, si des cartes sont sélectionnées.
    setBoolComponentChange(!boolComponentChange);
    _addCard(
      cardsInfo.availableCards,
      Math.floor(Math.random() * cardsInfo.availableCards.length)
    );
  };

  const removeCard = () => {
    //TODO: retravailler le loading de usePortee pour éviter d'avoir à flushSyncer et fucker le temps de chargement
    flushSync(_removeCard);
  };

  const _removeCard = () => {
    const tempCardArray = cardsInfo.cardsInGrid;
    tempCardArray[index] = null;
    setCardToDisplay(null);
    setCardsInfo((prev) => ({
      ...prev,
      cardsInGrid: tempCardArray,
      availableCards: cardsInfo.allCards
        .filter((card) => card.selected)
        .filter((card) => !isInGrid(card)),
    }));
    setBoolComponentChange(!boolComponentChange);
  };

  return (
    <Col
      ref={targetRef}
      onDragOver={dragOver}
      onDrop={dropped}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      xs={width}
      className={`grid-el grid-el-${cardOptions.height} ${
        cardToDisplay ? "" : "d-table"
      } ${sideBar !== "none" && "bordered-grid"}`}
    >
      {cardToDisplay ? (
        <>
          <button onClick={removeCard} className="removeCard disabledOnPlay">
            X
          </button>
          <Carte
            key={boolComponentChange}
            cardId={cardToDisplay.id}
            cardNumber={cardToDisplay.number}
            abcInfo={cardToDisplay.abcInfo}
            cardType={cardToDisplay.cardType}
            options={cardOptions}
            index={index}
            removeCard={removeCard}
            containerDimensions={gridElementDimensions}
          />
        </>
      ) : (
        <div className="pleaseChoose text-center d-table-cell align-middle">
          {sideBar !== "none" && (
            <>
              {t("interface.pleaseChoose")}
              <OverlayConditionnalButton
                text={t("interface.draw")}
                condition={cardsInfo.availableCards.length > 0}
                func={drawCard}
                className="custom-btn disabledOnPlay"
                tooltip={t("interface.pleaseSelectEnough")}
              />
            </>
          )}
        </div>
      )}
    </Col>
  );
}
