//Bouton qui affiche un message et qui est désactivé si une condition n'est pas remplie.

import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";

export default function OverlayConditionnalButton({
  text,
  condition,
  func,
  className,
  tooltip,
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const buttonBehavior = () => {
    condition && func();
  };

  const hover = (enter) => {
    !condition && setShow(enter);
  };

  return (
    <>
      <Button
        ref={target}
        className={className}
        onClick={buttonBehavior}
        onMouseEnter={() => hover(true)}
        onMouseLeave={() => hover(false)}
      >
        {text}
      </Button>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {tooltip}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}
