//Page d'administration des cartes et des utilisateurs.

import React, { useContext } from "react";

import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import { auth } from "../../utils/config/firebase/index";
import { AuthContext } from "../../context/Auth";
import { signOut } from "firebase/auth";

import "./Admin.scss";
//import CardCreator from "./CardCreator";

export default function Admin() {
  const { currentUser } = useContext(AuthContext);
  //TODO: Modifier avec les permissions des utilisateurs
  if (currentUser.uid !== process.env.REACT_APP_ADMIN_UID) {
    return (
      <div>
        <h1>Accès non autorisé.</h1>{" "}
        <Button onClick={() => signOut(auth)}>Se déconnecter</Button>
      </div>
    );
  } else {
    return (
      <>
        <Container fluid>
          <Row className="m-4">
            <Col>
              <h1 className="text-left section-title">
                Administration du contenu
              </h1>
            </Col>
            <Col className="text-right">
              <Button onClick={() => signOut(auth)} className="custom-btn">
                Se déconnecter
              </Button>
            </Col>
          </Row>
          <hr />
          <Tab.Container defaultActiveKey="utilisateurs">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="utilisateurs">Utilisateurs</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="cartes">Cartes</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} className="p-0 m-0">
                <Tab.Content id="cartes">
                  <Tab.Pane eventKey="utilisateurs">
                    <div>utilisateurs</div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="cartes">{/* <CardCreator /> */}</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </>
    );
  }
}
