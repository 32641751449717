// Le recto de la carte, avec le vrai contenu, selon qu'elle affiche une image ou une portée interactive.

import React from "react";
import CarteImage from "./CarteImage";

export default function CarteRecto({
  cardNumber,
  cardType,
  handleFlip,
  Portee,
  cardDimensions,
}) {
  const scoreTypes = ["melody", "rythm", "chords", "intervals"];

  return (
    <div
      style={{ height: cardDimensions.height, width: cardDimensions.width }}
      className={`verso-${cardType} text-center`}
      onClick={handleFlip}
    >
      {scoreTypes.includes(cardType) ? (
        <>
          <Portee />
        </>
      ) : (
        <div onClick={handleFlip}>
          <CarteImage cardNumber={cardNumber} cardType={cardType} />
        </div>
      )}
    </div>
  );
}
