//Application principale.

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import SelectSideBar from "./sidebar/SelectSideBar";
import PlaybackSettings from "./sidebar/PlaybackSettings";
import CardGrid from "./CardGrid";
import Help from "./sidebar/Help";

import { CardContextProvider } from "../../context/CardContext";
import { useTranslation } from "react-i18next";

import "./interface.scss";
import "./AllCards.scss";
import PlaybackBar from "./PlaybackBar";
import InterfaceDisabler from "./InterfaceDisabler";
//TODO: Arranger le menu collapsé
export default function AllCards() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [sideBar, setSideBar] = useState("none");
  //TODO: Corriger l'accès invalide

  return (
    <CardContextProvider>
      <InterfaceDisabler />
      <Navbar
        sticky="top"
        variant="dark"
        expanded={expanded ? "expanded" : ""}
        collapseOnSelect
        expand="xl"
        className="topBar"
      >
        <Navbar.Brand as={Link} to={"/"} className="ujm-brand">
          <span className="ujm-logo">UJM</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => setExpanded(expanded ? "" : "expanded")}
          className="border-0"
        >
          <div
            className={`custom-burger menu-bars ${expanded ? "expanded" : ""}`}
          >
            <span></span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="navbarScroll"
          expanded={expanded ? "expanded" : ""}
        >
          <PlaybackBar />

          <Nav className="ml-auto">
            {["cards", "options", "help"].map((type) => (
              <button
                key={type}
                type="button"
                className={`header_link ${sideBar === type ? "selected" : ""}`}
                onClick={() => {
                  setExpanded(false);
                  setSideBar(type);
                }}
                eventkey={type}
              >
                <span>{t(`common.${type}`)}</span>
              </button>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Row className="m-0 p-0">
        <Col className="m-0 p-0" xl={sideBar === "none" ? 12 : 9}>
          <CardGrid sideBar={sideBar} />
        </Col>
        <Col
          className="m-0 p-0"
          xl={3}
          style={sideBar === "none" ? { display: "none" } : {}}
        >
          <div className="p-3 sideBar text-right">
            <Button
              onClick={() => setSideBar("none")}
              className="custom-btn round-btn"
            >
              X
            </Button>
            {/* Avoir display: none alourdit le DOM, mais ça permet de désactiver les fonctions lorsque la musique joue même lorsqu'elles sont cachées */}
            <div style={sideBar === "cards" ? {} : { display: "none" }}>
              <SelectSideBar />
            </div>
            <div style={sideBar === "options" ? {} : { display: "none" }}>
              <PlaybackSettings />
            </div>
            <div style={sideBar === "help" ? {} : { display: "none" }}>
              <Help />
            </div>
          </div>
        </Col>
      </Row>
    </CardContextProvider>
  );
}
