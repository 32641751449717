//Contexte servant à charger et à maintenir l'état des cartes et des options.

import React, { useEffect, useState } from "react";
//import { useCards } from "../utils/functions/firebasefn";
import Loading from "../components/utils/Loading";

import cartes from "../resources/data/cartes.json";

export const CardContext = React.createContext();

export const CardContextProvider = ({ children }) => {
  //const { cards } = useCards();
  const cards = cartes;
  const [cardOptions, setCardOptions] = useState({
    bpm: 100,
    instrument: "piano",
    width: 2,
    height: 2,
  });
  const [cardsInfo, setCardsInfo] = useState({
    allCards: cards,
    availableCards: cards,
    cardsInGrid: [],
  });
  useEffect(() => {
    const numCards = cardOptions.height * cardOptions.width;
    setCardsInfo({
      allCards: cards,
      availableCards: cards,
      cardsInGrid: new Array(numCards).fill(null),
    });
  }, [cards, cardOptions.height, cardOptions.width]);

  const [playingQueue, setPlayingQueue] = useState([]);

  const [playingState, setPlayingState] = useState([]);

  if (cards.loading) {
    return <Loading />;
  } else {
    return (
      <CardContext.Provider
        value={{
          cardsInfo,
          setCardsInfo,
          cardOptions,
          setCardOptions,
          playingQueue,
          setPlayingQueue,
          playingState,
          setPlayingState,
        }}
      >
        {children}
      </CardContext.Provider>
    );
  }
};
