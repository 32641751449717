//Permet de s'authentifier dans l'application.

import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../../utils/config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import LoginFormVertical from "./LoginFormVertical";
import LoginFormHorizontal from "./LoginFormHorizontal";

const LoginForm = ({ history, to, loginLayout = "vertical" }) => {
  const { t } = useTranslation();
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const email = event.target[0].value;
      const password = event.target[1].value;
      // ! Façon hacky d'accéder au compte. À retirer.
      //TODO: Retirer
      const loginId = () => {
        if (email === process.env.REACT_APP_ADMIN_USERNAME) {
          return process.env.REACT_APP_ADMIN_EMAIL;
        } else if (process.env.REACT_APP_RANDOMUSER_USERNAME) {
          return process.env.REACT_APP_RANDOMUSER_EMAIL;
        } else {
          return email;
        }
      };

      try {
        await signInWithEmailAndPassword(auth, loginId(), password);
        history.push(to);
      } catch (error) {
        //TODO: Alerter l'erreur
        alert(t("login.error"));
      }
    },
    [history, t, to]
  );

  return (
    <>
      {loginLayout === "vertical" ? (
        <LoginFormVertical handleLogin={handleLogin} />
      ) : (
        <LoginFormHorizontal handleLogin={handleLogin} />
      )}
    </>
  );
};

export default withRouter(LoginForm);
