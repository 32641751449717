// Ce component est une carte réutilisable pour les 7 types de cartes.

import React, { useContext, useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import CarteRecto from "./CarteRecto";
import CarteVerso from "./CarteVerso";
import "./Carte.scss";
import usePortee from "../../utils/functions/usePortee";
import { CardContext } from "../../context/CardContext";
import { useCallback } from "react";
import { useMemo } from "react";

export default function Carte({
  cardId,
  cardNumber,
  abcInfo,
  cardType,
  options,
  index,
  removeCard,
  containerDimensions,
}) {
  const VERTICALTYPES = ["melody", "rythm", "notesAlteration", "symbols"];
  const SCORETYPES = useMemo(
    () => ["melody", "rythm", "chords", "intervals"],
    []
  );

  const cardDimensions = useMemo(() => {
    let height;
    let width;
    const coteMax = Math.min(
      containerDimensions.height,
      containerDimensions.width
    );
    const coteMin = (coteMax * 528) / 945;
    const coteMed = (coteMin * 733) / 528;

    if (["melody", "rythm"].includes(cardType)) {
      width = coteMax;
      height = coteMin;
    } else if (["creativity", "chords", "intervals"].includes(cardType)) {
      height = coteMed;
      width = coteMin;
    } else {
      width = coteMin;
      height = coteMin;
    }
    return { width: width, height: height };
  }, [containerDimensions, cardType]);

  const { playingQueue, setPlayingQueue, setPlayingState } =
    useContext(CardContext);
  const [playing, setPlaying] = useState("");
  useEffect(() => {
    setPlayingState((playingState) => {
      let tempPlayingState = [...playingState];
      tempPlayingState[index] = !(playing === "");
      return tempPlayingState;
    });
  }, [playing, setPlayingState, index]);

  const { Portee, play, stop, toggleLoop } = usePortee(
    cardType,
    `staff-${index}`,
    abcInfo,
    options
  );

  const [flipped, setFlipped] = useState(false);
  const [looping, setLooping] = useState(false);

  useEffect(() => {
    setFlipped(false);
  }, [abcInfo.abcString]);

  const handleFlip = () => setFlipped(!flipped);

  const handlePlay = useCallback(
    (e, onStartCallback = () => {}, onFinishedCallback = () => {}) => {
      if (SCORETYPES.includes(cardType)) {
        play(
          e,
          () => {
            setPlaying(`playing-${cardType}`);
            onStartCallback();
          },
          () => {
            setPlaying("");
            onFinishedCallback();
          }
        );
      } else {
        onFinishedCallback();
      }
    },
    [cardType, play, SCORETYPES]
  );

  const handleStop = useCallback(
    (e) => {
      if (SCORETYPES.includes(cardType)) {
        stop(e);
      }
      setPlaying("");
    },
    [stop, SCORETYPES, cardType]
  );

  useEffect(() => {
    let tempQueue = playingQueue;
    playingQueue[index] = { handlePlay: handlePlay, handleStop: handleStop };
    setPlayingQueue(tempQueue);
    return () => {
      delete tempQueue[index];
      setPlayingQueue(tempQueue);
    };
  }, [handlePlay, handleStop, index, playingQueue, setPlayingQueue]);

  const handleToggleLoop = () => {
    // Ça recharge la carte et arrête le playback, oups.
    setLooping(!looping);
    toggleLoop();
    setPlaying("");
  };

  //TODO: Draggable, de façon à déplacer la carte
  const dragStart = (e) => {
    e.dataTransfer.setData("id", cardId);
  };

  return (
    <>
      <div
        style={{ height: cardDimensions.height, width: cardDimensions.width }}
        className={`card-container ${playing}`}
        draggable
        onDragStart={dragStart}
      >
        <ReactCardFlip
          isFlipped={flipped}
          flipDirection={
            VERTICALTYPES.includes(cardType) ? "vertical" : "horizontal"
          }
          flipSpeedBackToFront={0.8}
          flipSpeedFrontToBack={0.8}
        >
          <CarteVerso
            cardType={cardType}
            handleFlip={handleFlip}
            cardDimensions={cardDimensions}
          />
          <CarteRecto
            cardNumber={cardNumber}
            cardType={cardType}
            abcInfo={abcInfo}
            handleFlip={handleFlip}
            options={options}
            index={index}
            Portee={Portee}
            looping={looping}
            cardDimensions={cardDimensions}
          />
        </ReactCardFlip>
      </div>
      {SCORETYPES.includes(cardType) && (
        <div className="card-control-bar">
          <button className="card-controls" onClick={handlePlay}>
            &#9654;
          </button>

          <button className="card-controls" onClick={handleStop}>
            &#9209;
          </button>

          <button
            className={`card-controls${looping ? "" : "-deactivated"}`}
            onClick={handleToggleLoop}
          >
            &#119046; &#119047;
          </button>
        </div>
      )}
    </>
  );
}
