//Page d'accueil

import React, { useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import Landing from "./Landing";
import { Helmet } from "react-helmet";
import LanguageSwitcher from "../../components/utils/LanguageSwitcher";
import LoginForm from "../../components/utils/LoginForm";
import { AuthContext } from "../../context/Auth";

export function Home({ history }) {
  const [expanded, setExpanded] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  const handleGoToCards = () => {
    if (currentUser) {
      history.push(t("routes:cards"));
    } else {
      setShowLogin(true);
    }
  };

  //TODO: Section "en savoir plus"
  return (
    <>
      <Helmet>
        <title>
          {t("common.title")} | {t("common.UJM")}
        </title>
      </Helmet>
      <Navbar
        sticky="top"
        variant="dark"
        expanded={expanded ? "expanded" : ""}
        collapseOnSelect
        expand="xl"
        className="navigationHeader"
      >
        <Navbar.Brand as={Link} to={"/"} className="ujm-brand">
          <span className="ujm-logo">UJM</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => setExpanded(expanded ? "" : "expanded")}
          className="border-0"
        >
          <div
            className={`custom-burger menu-bars ${expanded ? "expanded" : ""}`}
          >
            <span></span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="navbarScroll"
          expanded={expanded ? "expanded" : ""}
        >
          <Nav className="ml-auto"></Nav>
          <Nav className="ml-auto">
            {showLogin ? (
              <LoginForm
                history={history}
                to={t("routes:cards")}
                loginLayout="hoizontal"
              />
            ) : (
              <div className="header_link" onClick={handleGoToCards}>
                <span>{t("common.getCards")}</span>
              </div>
            )}
            <div className={showLogin ? "mt-3" : "mt-2"}>
              <LanguageSwitcher className="lang-switch mx-auto" />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Landing />

      <div style={{ height: "80vh" }} className="text-center m-5 p-5">
        <div className="m-5 p-5">{t("common.coming")}</div>
      </div>
    </>
  );
}

export default withRouter(Home);
