import React, { useContext, useEffect } from "react";
import { CardContext } from "../../context/CardContext";

export default function InterfaceDisabler() {
  const { playingState } = useContext(CardContext);
  useEffect(() => {
    if (playingState.filter((el) => el === true).length > 0) {
      let selection = document.querySelectorAll(".disabledOnPlay");
      if (selection) {
        let i;
        for (i = 0; i < selection.length; i++) {
          selection[i].disabled = true;
        }
      }
    } else {
      let selection = document.querySelectorAll(".disabledOnPlay");
      if (selection) {
        let i;
        for (i = 0; i < selection.length; i++) {
          selection[i].disabled = false;
        }
      }
    }
  }, [playingState]);
  return <></>;
}
