//Setup pour la traduction

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

i18n
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["fr", "en", "spa", "por"],
    preload: ["fr", "en", "spa", "por"],
    nonExplicitSupportedLngs: true,
    fallbackLng: "fr",
    ns: ["translation", "routes"],
    defaultNS: "translation",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["path"],
    },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
  });

export default i18n;
