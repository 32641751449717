// Le panneau d'aide de l'application

import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Help() {
  //TODO: Remplir
  return (
    <div className="m-5 p-5 text-right">
      <Button as={Link} to="/video-demo" target="_blank" className="custom-btn">
        Visionner le tutoriel
      </Button>
    </div>
  );
}
