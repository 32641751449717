//Permet de s'authentifier dans l'application.

import React, { useContext, useMemo } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/utils/LoginForm";
import { Helmet } from "react-helmet";

const Login = ({ history, location }) => {
  const { t } = useTranslation();

  const { currentUser } = useContext(AuthContext);

  const toLocation = useMemo(() => {
    if (
      location === undefined ||
      location.state === undefined ||
      location.state.from.pathname === undefined
    ) {
      return "/";
    } else {
      return location.state.from.pathname;
    }
  }, [location]);

  if (currentUser) {
    return <Redirect to={toLocation} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("common.login")} | {t("common.UJM")}
        </title>
      </Helmet>
      <div className="text-center m-5">
        <h1> {t("common.login")}</h1>
        <p className="">{t("login.pleaseConnect")}</p>
        <LoginForm history={history} to={toLocation} />
      </div>
    </>
  );
};

export default withRouter(Login);
