// Le sous-menu de sélection de cartes pour un type de carte donné.

import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import CardListItem from "./CardListItem";

import taginfo from "../../../resources/data/tags.json";

export default function CardCategory({
  searchedCardList,
  handleChooseCard,
  handleCheckCard,
  cardType,
  cardToDisplay,
  handleSelectAll,
}) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  // Pour afficher seulement les cartes de la catégorie pertinente.
  const cardTypeCardList = useMemo(
    () =>
      searchedCardList
        .filter((el) => el.cardType === cardType)
        .sort((a, b) => (parseInt(a.number) > parseInt(b.number) ? 1 : -1)),
    [searchedCardList, cardType]
  );

  const groupedTags = useMemo(() => {
    const taginfo_for_cardType = taginfo[cardType];
    let tempGroupedTags = [];
    if (
      taginfo_for_cardType instanceof Array &&
      taginfo_for_cardType.length !== 0
    ) {
      taginfo_for_cardType.forEach((tag) => {
        const currentTagGroup = tag.tagGroup[i18n.language];
        if (!tempGroupedTags.some((group) => currentTagGroup === group.name)) {
          tempGroupedTags.push({ name: currentTagGroup, tags: [] });
        }
        const indexOfCurrentGroup = tempGroupedTags.findIndex(
          (group) => currentTagGroup === group.name
        );
        tempGroupedTags[indexOfCurrentGroup].tags.push({
          [tag.tag[i18n.language]]: tag.priority,
        });
        tempGroupedTags[indexOfCurrentGroup].tags.sort(
          (a, b) => a.priority - b.priority
        );
      });
    }
    return tempGroupedTags;
  }, [cardType, i18n.language]);

  const [selectedTags, setSelectedTags] = useState(
    useMemo(() => {
      const taginfo_for_cardType = taginfo[cardType];
      let tags = {};
      if (
        taginfo_for_cardType instanceof Array &&
        taginfo_for_cardType.length !== 0
      ) {
        taginfo_for_cardType.forEach(
          (tag) => (tags = { ...tags, [tag.tag[i18n.language]]: true })
        );
      }
      return tags;
    }, [cardType, i18n.language])
  );

  const toggleSelectedTag = (e) => {
    const tag = e.target.value;
    const checked = selectedTags[tag];
    setSelectedTags({ ...selectedTags, [tag]: !checked });
  };

  //TODO: optimiser
  const [filteredCardList, setFilteredCardList] = useState(cardTypeCardList);
  useEffect(() => {
    let tempCardList = [];
    if (!Object.values(selectedTags).includes(false)) {
      tempCardList = cardTypeCardList;
      return;
    }
    cardTypeCardList.forEach((card) => {
      let statusList = [];
      groupedTags.forEach((group) => {
        let selectedTagsFromGroup = [];
        group.tags.forEach((tag) => {
          const tagName = Object.keys(tag)[0];
          if (selectedTags[tagName]) {
            selectedTagsFromGroup.push(tagName);
          }
        });
        let containsTags = false;
        selectedTagsFromGroup.forEach((tag) => {
          if (card.tags[i18n.language].includes(tag)) {
            containsTags = true;
            return;
          }
        });
        statusList.push(containsTags);
      });
      if (!statusList.includes(false) && statusList.length !== 0) {
        tempCardList.push(card);
      }
    });

    setFilteredCardList(tempCardList);
  }, [selectedTags, cardTypeCardList, i18n.language, groupedTags]);

  const mainCategories = useMemo(
    () => [
      ...new Set(filteredCardList.map((card) => card.category[i18n.language])),
    ],
    [filteredCardList, i18n.language]
  );

  return (
    <>
      {cardTypeCardList.length > 0 && (
        <>
          <h3 onClick={toggleOpen} className="cardType">
            {t(`cards.${cardType}`)} ({filteredCardList.length})
            {open ? <>&#9207;</> : <>&#9205;</>}
          </h3>

          {open && (
            <>
              <hr
                style={{
                  backgroundColor: "white",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              />
              <Form.Group>
                <div className="mb-3">
                  <span
                    className="allSelectSpan"
                    onClick={() => handleSelectAll(cardType, true)}
                  >
                    {t("interface.selectAll")}
                  </span>{" "}
                  /{" "}
                  <span
                    className="allSelectSpan"
                    onClick={() => handleSelectAll(cardType, false)}
                  >
                    {t("interface.deselectAll")}
                  </span>
                </div>

                {groupedTags.length !== 0 && (
                  <Container className="filtersContainer">
                    <h3 className="filtersTitle">{t("interface.filters")}</h3>
                    {groupedTags.map((tagGroup, index) => {
                      return (
                        <div key={index}>
                          <h4 className="filtersName">{tagGroup.name}</h4>
                          {tagGroup.tags.map((tag, index) => {
                            const tagName = Object.keys(tag);
                            return (
                              <Row
                                key={index}
                                className="filterListItem"
                                onClick={(
                                  e,
                                  info = { target: { value: tagName[0] } }
                                ) => toggleSelectedTag(info)}
                              >
                                <Col sm="1" />
                                <Col sm="1">
                                  <label className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      value={tagName}
                                      checked={selectedTags[tagName]}
                                      onChange={toggleSelectedTag}
                                      className="disabledOnPlay "
                                    />
                                    <span className="filter-checkbox custom-checkbox filterListItem"></span>
                                  </label>
                                </Col>
                                <Col sm="7">
                                  <button
                                    value={tagName}
                                    onClick={toggleSelectedTag}
                                    className={`disabledOnPlay filterListItem `}
                                  >
                                    {tagName}
                                  </button>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Container>
                )}
                {mainCategories.map((category) => (
                  <SubCategory
                    key={category}
                    category={category}
                    filteredCardList={filteredCardList}
                    cardType={cardType}
                    handleCheckCard={handleCheckCard}
                    handleChooseCard={handleChooseCard}
                  />
                ))}
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  );
}

function SubCategory({
  category,
  filteredCardList,
  cardType,
  handleCheckCard,
  handleChooseCard,
}) {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(true);

  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <h4 onClick={toggleOpen} className="cardCategory">
        {category} {open ? <>&#9207;</> : <>&#9205;</>}
      </h4>

      {open &&
        filteredCardList
          .filter((card) => card.category[i18n.language] === category)
          .map((element) => {
            return (
              <CardListItem
                key={element.id}
                id={element.id}
                number={element.number}
                title={element.title}
                abcInfo={element.abcInfo}
                selected={element.selected}
                cardType={cardType}
                handleCheckCard={handleCheckCard}
                handleChooseCard={handleChooseCard}
              />
            );
          })}
    </>
  );
}
