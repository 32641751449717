import React, { useContext, useState } from "react";
import { useRef } from "react";
import { CardContext } from "../../context/CardContext";

export default function PlaybackBar() {
  const [looping, setLooping] = useState(false);
  const stopped = useRef(true);
  const { playingQueue } = useContext(CardContext);

  const handlePlay = (e) => {
    stopped.current = false;
    playAll(e);
  };

  const playAll = (e) => {
    if (!stopped.current) {
      const queue = playingQueue.filter((n) => n);
      playNext(e, queue);
    }
  };

  const playNext = (e, queue) => {
    if (queue.length && !stopped.current) {
      queue.shift().handlePlay(
        e,
        () => {},
        () => playNext(e, queue)
      );
    } else if (looping) {
      playAll(e);
    }
  };
  const handleStop = (e) => {
    stopped.current = true;
    const queue = playingQueue.filter((n) => n);
    queue.forEach((card) => {
      card.handleStop(e);
    });
  };

  const handleToggleLoop = (e) => {
    setLooping(!looping);
    handleStop(e);
  };

  return (
    <div className="playback-bar text-center">
      <button className="global-controls" onClick={handlePlay}>
        &#9654;
      </button>

      <button className="global-controls" onClick={handleStop}>
        &#9209;
      </button>

      <button
        className={`global-controls${looping ? "" : "-deactivated"}`}
        onClick={handleToggleLoop}
      >
        &#119046; &#119047;
      </button>
    </div>
  );
}
