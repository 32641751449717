import React from "react";
import { useTranslation } from "react-i18next";

export default function CarteImage({ cardNumber, cardType }) {
  const { i18n } = useTranslation();

  const cardPath =
    cardType === "creativity"
      ? "creativity"
      : cardType === "symbols"
      ? "symbols"
      : `notesAlterations/${i18n.language}`;

  return (
    <img
      src={
        require(`../../resources/cards/${cardPath}/${
          cardNumber[i18n.language]
        }.png`).default
      }
      alt=""
      className="recto-image"
    />
  );
}
