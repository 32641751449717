import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./utils/config/App.scss";
import "./utils/config/i18n";
import { BrowserRouter } from "react-router-dom";
import Loading from "./components/utils/Loading";

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
