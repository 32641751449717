// Liste de cartes du côté.

import React, { useContext /* useState, useEffect */ } from "react";
//import Form from "react-bootstrap/Form";
import CardCategory from "./CardCategory";

import { useTranslation } from "react-i18next";
import OverlayConditionnalButton from "../../../components/utils/OverlayConditionnalButton";
import { CardContext } from "../../../context/CardContext";
//import { searchCard } from "../../../utils/functions/utils";

export default function SelectSideBar({
  cardToDisplay,
  setCardToDisplay,
  setBoolComponentChange,
  boolComponentChange,
}) {
  const { cardsInfo, setCardsInfo } = useContext(CardContext);
  const { t } = useTranslation();

  //TODO: cliquer sur une carte
  const handleChooseCard = (e) => {
    e.preventDefault();
    /*     setCardToDisplay(allCards.allCards.find((el) => el.id === e.target.value)); */
    /*  setBoolComponentChange(!boolComponentChange); */
  };

  const isInGrid = (cardToValidate) => {
    let isInGrid = false;
    const idToValidate = cardToValidate.id;
    cardsInfo.cardsInGrid
      .filter((o) => o)
      .forEach((card) => {
        if (card.id === idToValidate) {
          isInGrid = true;
        }
      });

    return isInGrid;
  };

  //Cocher/décocher une carte
  const handleCheckCard = (e) => {
    let tempAllCards = cardsInfo.allCards;
    tempAllCards.forEach((card) => {
      if (card.id.toString() === e.target.value) {
        card.selected = !card.selected;
      }
    });

    //Ajuste l'état coché/décoché
    setCardsInfo({
      allCards: tempAllCards,
      cardsInGrid: cardsInfo.cardsInGrid,
      availableCards: tempAllCards
        .filter((card) => card.selected)
        .filter((card) => !isInGrid(card)),
    });
    //Ajuste la liste de cartes dans laquelle on fait la recherche
    /* setSearchedCardList(
      searchExp === ""
        ? cardsInfo.allCards
        : searchCard(cardsInfo.allCards, searchExp.toLowerCase().split(" "))
    ); */
  };

  const handleSelectAll = (cardType, select) => {
    let tempAllCards = cardsInfo.allCards;
    tempAllCards.forEach((card) => {
      if (card.cardType === cardType) {
        card.selected = select;
      }
    });
    //Ajuste l'état coché/décoché
    setCardsInfo({
      allCards: tempAllCards,
      cardsInGrid: cardsInfo.cardsInGrid,
      availableCards: tempAllCards
        .filter((card) => card.selected)
        .filter((card) => !isInGrid(card)),
    });
    //Ajuste la liste de cartes dans laquelle on fait la recherche
    /* setSearchedCardList(
      searchExp === ""
        ? cardsInfo.allCards
        : searchCard(cardsInfo.allCards, searchExp.toLowerCase().split(" "))
    ); */
  };

  //TODO: piger une main
  const randomCards = () => {
    /* setCardToDisplay(
      allCards.availableCards[
        Math.floor(Math.random() * allCards.availableCards.length)
      ]
    );
    setBoolComponentChange(!boolComponentChange); */
  };

  /* const [searchExp, setSearchExp] = useState("");
  const [searchedCardList, setSearchedCardList] = useState(cardsInfo.allCards);
  useEffect(() => {
    //Au chargement de la page, effectue une recherche si une expression est déjà présente.
    setSearchedCardList(
      searchExp === ""
        ? cardsInfo.allCards
        : searchCard(cardsInfo.allCards, searchExp.toLowerCase().split(" "))
    );
  }, [cardsInfo.allCards, searchExp]); */

  //Effectue une recherche lorsqu'on tape dans la barre
  /* const handleSearch = (e) => {
    setSearchExp(e.target.value);
    setSearchedCardList(
      e.target.value === ""
        ? cardsInfo.allCards
        : searchCard(
            cardsInfo.allCards,
            e.target.value.toLowerCase().split(" ")
          )
    );
  }; */

  //Empêche de recharger lorsqu'on appuie sur enter.
  /* const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }; */

  return (
    <div className="text-left select-sidebar">
      {
        //TODO: Rétablir la recherche si on en veut vraiment
        /* <Form>
        <Form.Group controlId="search">
          <Form.Control
            name="search"
            placeholder={t("interface.search")}
            value={searchExp}
            onChange={handleSearch}
            onKeyDown={handleEnter}
          />
        </Form.Group>
      </Form> */
      }

      <OverlayConditionnalButton
        key={cardsInfo.availableCards}
        func={randomCards}
        className="custom-btn disabledOnPlay"
        condition={
          false
          //cardsInfo.availableCards.length !== 0
        }
        text={t("interface.random")}
        tooltip={
          t("interface.coming")
          //t("interface.pleaseSelect")
        }
      />
      {[
        "melody",
        "rythm",
        "chords",
        "intervals",
        "notesAlterations",
        "symbols",
        "creativity",
      ].map((type) => (
        <CardCategory
          key={type}
          handleChooseCard={handleChooseCard}
          searchedCardList={cardsInfo.allCards}
          cardType={type}
          cardToDisplay={cardToDisplay}
          handleCheckCard={handleCheckCard}
          handleSelectAll={handleSelectAll}
        />
      ))}
    </div>
  );
}
