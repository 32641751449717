import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

import drapeauCan from "../../resources/media/drapeau_can.png";
//import drapeauBres from "../../resources/media/drapeau_bres.png";
//import drapeauEs from "../../resources/media/drapeau_esp.png";

import { createBrowserHistory } from "history";
let history = createBrowserHistory();

//TODO: Remettre les autres langues une fois la traduction complétée
export default function LanguageSwitcher({ className }) {
  const lngs = {
    fr: { nativeName: "Français", flag: drapeauCan },
    //en: { nativeName: "English", flag: drapeauCan },
    //spa: { nativeName: "Español", flag: drapeauEs },
    //por: { nativeName: "Português", flag: drapeauBres },
  };

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const changeUrlFromLng = (nextLanguage) => {
    const possibleRoutes = [
      i18n.getResourceBundle("fr", "routes"),
      i18n.getResourceBundle("en", "routes"),
      i18n.getResourceBundle("spa", "routes"),
      i18n.getResourceBundle("por", "routes"),
    ];
    console.log(possibleRoutes);

    const currentPathname = window.location.pathname.replace(/\/+$/, "");

    const currentRoute = getKeyByValue(possibleRoutes[0], currentPathname)
      ? getKeyByValue(possibleRoutes[0], currentPathname)
      : getKeyByValue(possibleRoutes[1], currentPathname)
      ? getKeyByValue(possibleRoutes[1], currentPathname)
      : getKeyByValue(possibleRoutes[2], currentPathname)
      ? getKeyByValue(possibleRoutes[2], currentPathname)
      : getKeyByValue(possibleRoutes[3], currentPathname);

    history.push(i18n.t(`routes:${currentRoute}`));

    console.log(i18n.t(`routes:${currentRoute}`));
  };

  i18n.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
  });

  return (
    <Dropdown id="language-switcher">
      <Dropdown.Toggle className={className}>
        <img
          src={lngs[i18n.language].flag}
          alt="flag "
          className="countryIcon"
        />
        {lngs[i18n.language].nativeName}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(lngs).map((lng) => (
          <Dropdown.Item
            key={lng}
            onClick={() => {
              changeLanguage(lng);
              changeUrlFromLng(i18n.language);
              window.location.reload();
            }}
            eventKey={lng}
            className="dropdownMenu"
          >
            <img src={lngs[lng].flag} alt="flag " className="countryIcon" />
            {lngs[lng].nativeName}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
