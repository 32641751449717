import React, { useContext, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

import rythmique_thumb from "../../../resources/media/rythm_fr_thumb.png";
import accords_thumb from "../../../resources/media/chords_fr_thumb.png";
import creativite_thumb from "../../../resources/media/creativity_fr_thumb.png";
import intervalles_thumb from "../../../resources/media/intervals_fr_thumb.png";
import melodique_thumb from "../../../resources/media/melody_fr_thumb.png";
import symboles_thumb from "../../../resources/media/symbols_fr_thumb.png";
import notes_alterations_thumb from "../../../resources/media/notesAlterations_fr_thumb.png";
import CardPreview from "./CardPreview";
import { useState } from "react";
import { CardContext } from "../../../context/CardContext";

export default function CardListItem({
  id,
  number,
  title,
  abcInfo,
  selected,
  cardType,
  handleCheckCard,
  handleChooseCard,
}) {
  // TODO: Créer les thumbs dans les autres langues et les intégrer.
  const { playingState } = useContext(CardContext);
  const { t, i18n } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const lng = i18n.language;

  const imageTable = {
    rythm: rythmique_thumb,
    chords: accords_thumb,
    creativity: creativite_thumb,
    intervals: intervalles_thumb,
    melody: melodique_thumb,
    symbols: symboles_thumb,
    notesAlterations: notes_alterations_thumb,
  };
  let dragImages = {};
  // C'est la façon weird que j'ai trouvé de créer les éléments du DOM pour pouvoir les dragger dès le premier essai. Sinon, la première tentative de dragger charge l'image, qui n'est affichée que la deuxième fois.
  for (let cardType in imageTable) {
    const image = new Image();
    image.src = imageTable[cardType];
    dragImages[cardType] = image;
  }
  //TODO: Plus beau truc à draguer
  const dragStart = (e, id, cardType) => {
    // Définit les propriétés du drag and drop qui sert à afficher des cartes.
    const dragImage = dragImages[cardType];
    e.dataTransfer.setDragImage(dragImage, 0, 0);

    e.dataTransfer.setData("id", id);
  };

  const handleShowPreview = (e) => {
    setShowPreview(true);
  };

  const handleHidePreview = (e) => {
    setShowPreview(false);
  };

  const [isDraggable, setIsDraggable] = useState(true);

  useEffect(() => {
    if (playingState.filter((el) => el === true).length > 0) {
      setIsDraggable(false);
    } else {
      setIsDraggable(true);
    }
  }, [playingState]);

  return (
    <div
      draggable={isDraggable}
      onDragStart={(e) => dragStart(e, id, cardType)}
      className={`selectionList selectionListDraggable${isDraggable}`}
      key={id}
      onMouseEnter={handleShowPreview}
      onMouseLeave={handleHidePreview}
      onMouseOver={handleShowPreview}
    >
      <Row>
        <Col sm="1" />
        <Col sm="1">
          <label className="checkbox-container">
            <input
              type="checkbox"
              value={id}
              checked={selected}
              onChange={handleCheckCard}
              className=" "
            />
            <span className="custom-checkbox"></span>
          </label>
        </Col>
        <Col sm="9">
          {/* TODO: Arranger le soulignement lorsque la carte est présentement affichée. */}
          <button
            value={id}
            onClick={handleCheckCard}
            /* className={`disabledOnPlay selectionListItem ${
                        cardToDisplay && element.id === cardToDisplay.id
                          ? "selectionSpan selectionSpan-active"
                          : "selectionSpan"
                            }`} */
            className={`selectionListItem `}
          >
            {title[lng] === "" || title[lng] === undefined
              ? `${t(`cards.${cardType}`)} ${number[lng]}`
              : title[lng]}
          </button>
          {/* </OverlayTrigger> */}
        </Col>
      </Row>
      {showPreview && (
        <Row className="text-center justify-content-center">
          <CardPreview
            cardType={cardType}
            cardNumber={number}
            abcInfo={abcInfo}
          />
        </Row>
      )}
    </div>
  );
}
