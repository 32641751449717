import React from "react";
import usePortee from "../../../utils/functions/usePortee";
import CarteImage from "../CarteImage";

export default function CardPreview({ cardType, cardNumber, abcInfo }) {
  const SCORETYPES = ["melody", "rythm", "chords", "intervals"];

  const { Portee } = usePortee(
    cardType,
    "card-preview",
    abcInfo,
    {
      bpm: 100,
      instrument: "piano",
    },
    true
  );
  return SCORETYPES.includes(cardType) ? (
    <div
      className={`card-preview-container card-preview-container-${cardType}`}
    >
      <Portee />
    </div>
  ) : (
    <div className="card-preview-container">
      <CarteImage cardNumber={cardNumber} cardType={cardType} />
    </div>
  );
}
