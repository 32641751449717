import Home from "./pages/home/Home";
import { Route, Switch } from "react-router";
import { useTranslation } from "react-i18next";
import AllCards from "./pages/cartes/AllCards";
import PrivateRoute from "./components/utils/PrivateRoute";
import Admin from "./pages/admin/Admin";
import { AuthProvider } from "./context/Auth";
import IndexCards from "./pages/IndexCards";
import Login from "./pages/login/Login";

import video_tutorial_webm from "./resources/media/demo-cartes.webm";
import video_tutorial_mp4 from "./resources/media/demo-cartes.mp4";

function App() {
  const { t } = useTranslation();
  return (
    <>
      <AuthProvider>
        <Switch>
          <Route
            exact
            path={`/:locale(en|fr|spa|por)?${t("routes:home")}`}
            component={Home}
          />
          <Route
            exact
            path={`/:locale(en|fr|spa|por)?${t("routes:indexcards")}`}
            component={IndexCards}
          />
          <PrivateRoute
            exact
            path={`/:locale(en|fr|spa|por)?${t("routes:cards")}`}
            component={AllCards}
          />
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/video-demo" component={VideoPlayback} />
          <Route path="/login" component={Login} />
        </Switch>
      </AuthProvider>
    </>
  );
}

export default App;

const VideoPlayback = () => {
  return (
    <video style={{ maxWidth: "100vw", maxHeight: "100vh" }} controls>
      <source src={video_tutorial_webm} type="video/webm" />
      <source src={video_tutorial_mp4} type="video/mp4" />
    </video>
  );
};
