// Permet d'ajuster les options pour plus que juste le Playback: tempo, instrument, taille de la grille.

import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { CardContext } from "../../../context/CardContext";

import { useTranslation } from "react-i18next";

export default function PlaybackSettings() {
  const { t } = useTranslation();
  const { cardOptions, setCardOptions } = useContext(CardContext);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCardOptions((prev) => ({ ...prev, [name]: value }));
  };
  //TODO: Faire des sections pour interface / playback
  //TODO: Dans interface, ajouter langue
  return (
    <div className="text-center m-4">
      <Form.Label className="mt-4">{t("interface.grid")}</Form.Label>
      <Form.Group as={Row}>
        <Col xl="5">
          <Form.Control
            type="number"
            placeholder="#"
            name="width"
            value={cardOptions.width}
            onChange={handleInput}
            min={1}
            max={4}
            className="disabledOnPlay"
          />
          <Form.Label>{t("interface.col")}</Form.Label>
        </Col>
        <Form.Label column xl="2">
          X
        </Form.Label>
        <Col xl="5">
          <Form.Control
            type="number"
            placeholder="#"
            name="height"
            value={cardOptions.height}
            onChange={handleInput}
            min={1}
            max={4}
            className="disabledOnPlay"
          />
          <Form.Label>{t("interface.row")}</Form.Label>
        </Col>
      </Form.Group>

      <ButtonGroup className="my-4">
        <Button
          name="instrument"
          value="piano"
          onClick={handleInput}
          className={`${
            cardOptions.instrument === "piano" ? "custom-btn-active" : ""
          } custom-btn m-0  disabledOnPlay`}
        >
          {t("interface.piano")}
        </Button>
        <Button
          name="instrument"
          value="violin"
          onClick={handleInput}
          className={`${
            cardOptions.instrument === "violin" ? "custom-btn-active" : ""
          } custom-btn m-0 disabledOnPlay`}
        >
          {t("interface.violin")}
        </Button>
        <Button
          name="instrument"
          value="guitar"
          onClick={handleInput}
          className={`${
            cardOptions.instrument === "guitar" ? "custom-btn-active" : ""
          } custom-btn m-0 disabledOnPlay`}
        >
          {t("interface.guitar")}
        </Button>
      </ButtonGroup>

      <Form.Control
        value={cardOptions.bpm}
        type="range"
        min="50"
        max="200"
        name="bpm"
        className="disabledOnPlay mt-4"
        onChange={handleInput}
        custom
      />
      <Form.Label>Tempo= {cardOptions.bpm}bpm</Form.Label>
    </div>
  );
}
